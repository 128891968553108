import React from 'react'
import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout>
    <h1>NOT FOUND</h1>
    <p>This page doesn't exist. You probably want to go <a href="/">here</a></p>
  </Layout>
)

export default NotFoundPage
